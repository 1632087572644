import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ProjectCard from './card';
import { StaticQuery, graphql } from "gatsby";


const subCategories = [
    {
        'mdx': 'hackathon',
        'title': 'Hackathons',
        'tagline': 'Most of these were made under 24 hours and probably with <a class="textLinks" href="https://github.com/Team-Probably" target="_blank">Team Probably</a>.'
    },
    {
        'mdx': 'college',
        'title': 'College Assignments',
        'tagline': 'Most of these were made solely for fun and to fullfil the urge to try something new while also completing college work. Some of these are solo projects and some were made with <a class="textLinks" href="https://github.com/2knal" target="_blank">@2knal</a>'
    },
    {
        'mdx': 'opensource',
        'title': 'Open Source',
        'tagline': "I guess the title does a really good job of explaining what these projects are for."
    },
    {
        'mdx': 'codecell',
        'title': 'KJSCE CodeCell',
        'tagline': 'Made for activities and events of coding club that I was part of in my college. My contribution varies widely in these projects.'
    },
    {
        'mdx': 'cybersec',
        'title': 'Cyber Security',
        'tagline': 'I feel the title might be a bit misleading at the moment, since all I have here is CTF projects done for CTF sessions.'
    },
    {
        'mdx': 'misc',
        'title': 'Miscellaneous',
        'tagline': "Projects that don't fit anywhere else come down here."
    }
]


function getProjects(data, subCategory) {
    let p = []
    const { edges: projects } = data.allMdx;
    for (let i = 0; i < projects.length; i++) {
        const ele = projects[i].node;
        if (ele.frontmatter.subcategory === subCategory.mdx &&
            ele.frontmatter.type !== 0 &&
            ele.frontmatter.public) { 
            p.push(
                <Grid item xs={12} sm={6} md={6}>
                    <ProjectCard details={ele} />
                </Grid>
            )
        }

    }
    return p;
}

function createCategories(data) {
    const { edges: x } = data.allMdx;
    let categories = []
    for (let i = 0; i < subCategories.length; i++) {
        const subCategory = subCategories[i];
        categories.push(
            <Grid container item xs={12} spacing={2}>
                <Grid item >
                    <Typography variant="h5" component="h4">
                        {subCategory.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" component="h6" className='indent-quote'>
                        <div dangerouslySetInnerHTML={{ __html: subCategory.tagline  }} />
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    {getProjects(data, subCategory)}
                </Grid>
            </Grid>
        )
    }
    return categories
}

export default function AllProjects(props) {
    return (
        <StaticQuery
            query={graphql`
            query {
                allMdx(filter: {frontmatter: {category: {in: ["projects", "experiments"]}}}, sort: {fields: frontmatter___date, order: DESC}) {
                    edges {
                    node {
                        excerpt
                        frontmatter {
                        category
                        code
                        comment
                        date(formatString: "MMMM DD, YYYY")
                        excerpt
                        image
                        index
                        mainpage
                        public
                        subcategory
                        title
                        type
                        url
                        }
                    }
                    }
                }
            }
        `}

            render={data => (
                <Grid container spacing={5}>
                    {createCategories(data)}
                </Grid>
            )}
        />
    );
}