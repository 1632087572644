import React from "react"
import { Link } from "gatsby"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AllProjects from '../components/projects_page/subcategories';
import Layout from "../components/layout"
import SEO from "../components/seo"


export default function Projects() {
    return (
        <Layout page="projects">
            <SEO title="Projects" />
            <div className='full_background'>
                <Container maxWidth="xl" className='data_header'>
                    <Container maxWidth="md" style={{
                        paddingTop: '8rem',
                        paddingBottom: '4rem', textAlign: 'center'
                    }}>
                        <Typography variant="h3" component="h3">
                            <b>
                                Projects
                        </b>
                        </Typography>
                        <br></br>
                        <Typography variant="body1" component="h5">
                            A kinda comprehensive index of all public projects I have built or contributed to.
                    </Typography>
                    </Container>
                </Container>
                <Container maxWidth="md" style={{
                    paddingTop: '3rem',
                    paddingBottom: '4rem'
                }}>
                    {/* <div className='indent-quote'>
                        <Typography variant="body1" component="h5">
                            A kinda comprehensive index of all public projects I have built or contributed to.
                        </Typography>
                    </div> */}
                    <Typography variant="body1" component="h6" style={{ marginBottom: '50px' }}>
                        Some projects have overly dramatic experience and something that might be insightful for you,<br />
                    click on 'Read more' to read my full experience and to also check out the code of the respective project.
                    </Typography>
                    <AllProjects />
                    <div className='indent-quote' style={{marginTop:'2rem'}}>
                        <Typography variant="body1" component="h5">
                            Also while you are in my realm, have a look at some of the <Link className='textLinks' to='/blogs'>blogs</Link> (not written by me, so you can expect better English) and also check out other <Link className='textLinks' to='/stuff'>stuff</Link> which I wanted to share but the dumb AI failed to classify them into projects/blogs.
                        </Typography>
                    </div>
                </Container>
            </div>
        </Layout>
    )
}
